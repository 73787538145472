<template>
  <div style="height: inherit">

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="9"
            >
              <b-form-input
                v-model="newMod.name"
              />
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="text-right"
            >
              <b-button
                class="mt-md-0 mt-1"
                @click="updateModality(newMod)"
              >
                {{ modifText }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-overlay
      :show="deleteWarning"
      rounded="sm"
    >
      <b-list-group>
        <b-list-group-item
          v-for="mod in modalities"
          :key="mod.id"
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <span>
              {{ mod.name }}
            </span>
            <b-badge
              variant="secondary"
              class="ml-1"
            >
              {{ mod.count }} {{ mod.count > 1 ? 'aulas' : 'aula' }}
            </b-badge>
          </div>
          <div>
            <b-button
              variant="primary"
              class="btn-icon mr-1"
              @click="edit(mod.id)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <b-button
              variant="danger"
              class="btn-icon mr-1"
              @click="validarDelete(mod.id)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            Você quer deletar a modalidade: <b>{{ toDelete.name }}</b> ?
          </p>
          <b-button
            ref="delete"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="deletar"
          >
            Deletar
          </b-button>
          <b-button
            ref="cancel"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="cancelDelete"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BListGroup, BBadge, BListGroupItem, BButton, BRow, BCol, BFormInput, BCard, BOverlay, BIcon,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BBadge,
    BListGroupItem,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BOverlay,
    BIcon,
  },
  data() {
    return {
      toDelete: {},
      deleteWarning: false,
      newMod: {
        name: '',
      },
      modifText: 'Adicionar',
    }
  },
  computed: {
    modalities() {
      return this.$store.state.quales.modalities
    },
  },
  beforeCreate() {
    store.dispatch('quales/getModalities')
  },
  methods: {
    edit(modId) {
      [this.newMod] = this.$store.state.quales.modalities.filter(m => m.id === modId)
      this.modifText = 'Gravar'
    },
    updateModality(modality) {
      this.$store.dispatch('quales/updateModality', modality).then(() => {
        this.newMod = {
          name: '',
        }
        this.modifText = 'Adicionar'
        this.$store.dispatch('quales/getModalities')
      })
    },
    cancelDelete() {
      this.toDelete = {}
      this.deleteWarning = false
    },
    validarDelete(id) {
      [this.toDelete] = this.$store.state.quales.modalities.filter(c => c.id === id)
      this.deleteWarning = true
    },
    deletar() {
      this.$store.dispatch('quales/deleteModality', this.toDelete.id).then(() => {
        this.toDelete = {}
        this.deleteWarning = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Modalidade',
            icon: 'UserCheckIcon',
            variant: 'danger',
            text: 'Modalidade deletada',
          },
        })
        this.$store.dispatch('quales/getModalities')
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Modalidade',
              icon: 'UserCheckIcon',
              variant: 'warning',
              text: 'Não conseguimos deletar essa modalidade, verifique que nenhuma aula esteja registrada com ela',
            },
          })
          this.toDelete = {}
          this.deleteWarning = false
        })
    },
  },
}
</script>

<style lang="scss">
</style>
